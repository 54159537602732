export default {

    methods: {
        async loadPaymentProviders(returnObject) {
            const psiKeyPattern = "core.payment.psi";
            const lookupRes =
                await this.$store.$coreApi.coreConfigurationApi.configLookup(
                    this.selectedDomain,
                    this.keySpace,
                    psiKeyPattern + ".*",
                    {
                        lookupRegion: ["data"],
                        keyMatching: "completeKey",
                    }
                );


            const paymentProviders = (lookupRes ?? []).reduce((providers, item) => {
                const providerId = item.key.replace(psiKeyPattern + ".", "");
                if (returnObject) {
                    if (!providers.some(({ key }) => key === item.key)) {
                        providers.push({ id: providerId, ...item });
                    }
                } else {
                    if (!providers.includes(providerId)) {
                        providers.push(providerId);
                    }
                }
                return providers;
            }, []);
            this.paymentProviders = paymentProviders;
        },
    },

    computed: {
        keySpace() {
            return "Configuration"
        },

        selectedDomain() {
            return this.$store.state.selectedDomain;
        }
    }

}