<template>
  <div class="payment-methods-overview">
    <v-data-table
      disable-pagination
      hide-default-footer
      class="payment-methods-table"
      fixed-header
      single-select
      show-expand
      data-test-id="paymentMethodTable"
      multi-sort
      :key="updateMethods"
      :group-by="groupBy"
      :items-per-page="-1"
      :height="tableHeight"
      :items="paymentMethods"
      :headers="headers"
      :loading="loading"
      :item-class="getItemClass"
      :expanded.sync="expanded"
      :options.sync="options"
    >
      <!-- eslint-disable-next-line -->
      <template #group.header="{ group, isOpen, toggle }">
        <td :colspan="headers.length" :data-test-id="'group_header_' + group">
          <div class="d-flex align-center pa-1">
            <v-icon
              @click="toggle"
              :data-test-id="'group_' + group + '_toogle_btn'"
            >
              {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
            </v-icon>
            <div class="ml-3 font-weight-medium text-truncate">
              {{ group }}
            </div>
          </div>
        </td>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          icon
          v-if="isExpanded"
          @click="expand(false)"
          :data-test-id="'payment_method_' + item.id + '_hide_btn'"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          icon
          v-else
          @click="expand(true)"
          :data-test-id="'payment_method_' + item.id + '_expand_btn'"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.id="{ item }">
        <v-input
          hide-details="auto"
          :error-messages="getMethodViolations(item)"
        >
          <div
            :class="{
              'font-weight-bold': hasChanged(item),
              'font-weight-bold error--text':
                methodHasViolation(item) || item.valid === false,
            }"
            :data-test-id="'paymentMethodName_' + item.id"
          >
            {{ item.id + (hasChanged(item) ? "*" : "") }}
          </div>
        </v-input>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.provider="{ item }">
        <div v-if="!isExpanded(item)">{{ item.provider }}</div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.enabled="{ item }">
        <DataEditor
          v-if="!loading && !isExpanded(item)"
          :uimodel="item.enabledModel"
          :disabled="loading"
          :show-label="false"
          ext-view-mode="edit"
          @restore="restoreEnabled(item)"
          @input="updateEnabled(item.id, $event)"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <div class="d-flex flex-row justify-end">
          <v-btn
            v-if="hasChanged(item) && !isExpanded(item)"
            icon
            :data-test-id="'paymentMethodRestoreBtn_' + item.id"
            @click="restoreModel(item)"
          >
            <v-icon>mdi-restore</v-icon>
          </v-btn>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1" class="py-2 px-1">
          <PaymentMethod
            :method="item"
            :key="updateMethods"
            :loading="loading"
            :data-test-id="'methodDetail_' + item.id"
            @update="updateMethodData(item.id, $event)"
            @update:validity="updateMethodValidity(item, $event)"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataEditor from "components/configuration/data/editors/DataEditor";
import PaymentMethod from "./PaymentMethod";

import dataEditorMixin from "mixins/data-editor-mixin";
import paymentMixin from "mixins/payment-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";

export default {
  mixins: [paymentMixin, sortQueryMixin, dataEditorMixin],
  inject: {
    getEditorHeight: {
      default() {
        console.warn("Parent does not provide getEditorHeight function");
        return () => {
          return null;
        };
      },
    },

    getViolations: {
      default() {
        console.warn("Parent does not provide getViolations function");
        return () => {
          return null;
        };
      },
    },

    setMethodValidity: {
      default() {
        console.warn("Parent does not provide setMethodValidity function");
        return () => {
          return null;
        };
      },
    },
  },

  components: {
    DataEditor,
    PaymentMethod,
  },

  props: {
    uimodel: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    groupByProviders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      paymentMethods: [],
      groupBy: [],
      expanded: [],
      data: null,
      options: {
        sortBy: ["provider", "id"],
        sortDesc: [true, false],
      },
      updateMethods: 0,
    };
  },

  mounted() {
    const routeQuery = this.$route.query;
    let sort = routeQuery.sort;
    let groupByProviders = routeQuery["group-providers"];

    if (sort) {
      const options = this.parseSortQueryToOptions(sort);
      this.options = Object.assign(this.options, options);
    }
    this.groupByProviders = groupByProviders === "true";

    this.data = this.$cloneObject(this.uimodel.data);
    this.buildMethodModels();
  },

  watch: {
    "uimodel.data": {
      handler: function (data) {
        if (!this.$isEqual(this.data, data)) {
          this.data = this.$cloneObject(data);
          this.buildMethodModels();
        }
      },
      deep: true,
    },

    data: {
      handler: function (data) {
        this.$emit("input", data);
      },
      deep: true,
    },

    groupByProviders(groupByProviders) {
      this.groupBy = groupByProviders ? ["provider"] : [];
      let query = Object.assign({}, this.$route.query);
      this.$set(query, "group-providers", groupByProviders);
      this.$router.push({
        ...this.$route,
        query,
      });
    },

    options(options) {
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({
        ...this.$route,
        query,
      });
    },

    async loading(loading) {
      if (loading === false) {
        await this.$nextTick();
        this.expanded = this.paymentMethods.reduce((array, method) => {
          if (this.methodHasViolation(method) || method.valid === false) {
            array.push(method);
          }
          return array;
        }, []);
        this.updateMethods++;
      }
    },
  },

  methods: {
    buildMethodModels() {
      const subUimodels = this.buildSubUimodels(this.uimodel);
      const paymentMethodKeys = Object.keys(subUimodels ?? {});
      this.paymentMethods = paymentMethodKeys.map((methodId) =>
        this.buildMethodModel(methodId, subUimodels[methodId])
      );

      this.updateMethods++;
    },

    buildMethodModel(methodId, uimodel) {
      if (!uimodel) {
        const uimodels = this.buildSubUimodels(this.uimodel);
        uimodel = uimodels?.[methodId];
      }

      //Set certain properties to readonly
      let schema = uimodel.schema;
      this.readOnlyProperties.forEach((property) => {
        let propertySchema = schema?.properties?.[property];
        if (propertySchema) {
          if (!propertySchema?.dataUI) {
            this.$set(propertySchema, "dataUI", {});
          }
          this.$set(propertySchema.dataUI, "readonly", true);
        }
      });
      const provider = uimodel?.mergedData?.provider;
      const subUiModels = this.buildSubUimodels(uimodel);
      return {
        id: methodId,
        name: methodId,
        provider,
        uimodel,
        enabledModel: subUiModels.enabled,
      };
    },

    updateMethodData(methodId, data) {
      if (!this.data) this.data = {};
      this.$set(this.data, methodId, data);
      this.$nextTick(() => this.updateModel(methodId));
    },

    updateMethodValidity(methodModel, isValid) {
      this.$set(methodModel, "valid", isValid);
      this.$nextTick(() => {
        this.setMethodValidity(methodModel.id, isValid);
      });
    },

    updateModel(methodId) {
      const updateModel = this.buildMethodModel(methodId);
      const idx = this.paymentMethods.findIndex((item) => item.id === methodId);
      this.paymentMethods.splice(idx, 1, updateModel);
    },

    restoreModel({ uimodel, id }) {
      this.restoreProperty(uimodel);
      this.$nextTick(() => {
        this.updateModel(id);
        //Close method on restore
        this.expanded = this.expanded.filter(
          (methodModel) => methodModel.id !== id
        );
      });
    },

    updateEnabled(method, enabled) {
      if (!this.data) this.data = {};
      if (!this.data[method]) this.$set(this.data, method, {});
      this.$set(this.data[method], "enabled", enabled);
      this.$nextTick(() => this.updateModel(method));
    },

    restoreEnabled({ enabledModel, id }) {
      this.restoreProperty(enabledModel, this.data?.[id]);
      this.$set(enabledModel, "data", this.data[id].enabled);
    },

    hasChanged({ uimodel }) {
      return !this.$isEqual(uimodel.data, uimodel.savedData);
    },

    getItemClass(methodModel) {
      let itemClass = ["payment-method-" + methodModel.id];
      if (this.hasChanged(methodModel)) {
        itemClass.push("changed");
      }

      if (methodModel.valid === false || this.methodHasViolation(methodModel)) {
        itemClass.push("has-violation");
      }

      return itemClass.join(" ");
    },

    getMethodViolations(methodModel) {
      const allViolations = this.getViolations(null, { returnObject: true });
      const path = methodModel.uimodel.path.split("/").join(".");
      const violations = allViolations.reduce(
        (array, { property, message }) => {
          if (property.startsWith(path)) {
            array.push(message);
          }
          return array;
        },
        []
      );
      return violations;
    },

    methodHasViolation({ uimodel }) {
      const path = uimodel?.path?.split("/")?.join(".");
      const allViolations = this.getViolations(null, { returnObject: true });
      return allViolations.some(({ property }) => {
        return property.startsWith(path);
      });
    },

    isExpanded({ id }) {
      return this.expanded.some((item) => item.id === id);
    },

    validate() {
      const hasInvalid = this.paymentMethods.some(
        (methodModel) => methodModel.valid === false
      );
      return !hasInvalid;
    },
  },

  computed: {
    tableHeight() {
      return this.getEditorHeight() - 28 - 12;
    },

    keyPattern() {
      return "core.payment.methods";
    },

    headers() {
      return [
        { text: "Method", value: "id" },
        { text: "Provider", value: "provider" },
        { text: "Enabled", value: "enabled", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },

    readOnlyProperties() {
      return ["id", "code", "provider", "currencies"];
    },
  },
};
</script>

<style scoped>
.payment-methods-overview {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
}

.payment-methods-overview > .payment-methods-toolbar {
  flex: 0 1 auto;
}

.payment-methods-overview
  > .payment-methods-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-row-group__header) {
  height: 60px;
}

.payment-methods-overview
  > .payment-methods-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-row-group__header):not(.changed):hover {
  background: initial !important;
}

.payment-methods-overview
  > .payment-methods-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.has-violation {
  outline: 2px solid var(--v-error-base);
  outline-offset: -2px;
}

.payment-methods-overview
  > .payment-methods-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.changed {
  background: rgba(0, 160, 227, 0.1) !important;
}
</style>