<template>
  <DialogForm :title="title" fixed-header flat>
    <template #actions>
      <div />
    </template>

    <template #form>
      <div :id="editorId">
        <v-form>
          <DataEditor
            v-if="uimodel"
            :uimodel="uimodel"
            :disabled="loading"
            @input="updateMethod"
            @restore="restoreMethod"
          />
        </v-form>
      </div>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "components/common/templates/DialogForm";
import DataEditor from "components/configuration/data/editors/DataEditor";
export default {
  components: {
    DialogForm,
    DataEditor,
  },

  props: {
    method: {
      required: true,
      type: Object,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      uimodel: null,
      hasChanged: false,
    };
  },

  mounted() {
    this.uimodel = this.$cloneObject(this.method?.uimodel);
    this.$nextTick(() => this.validate());
  },

  watch: {
    "uimodel.data": function (data) {
      this.hasChanged = !this.$isEqual(data, this.uimodel.savedData);
    },
  },

  methods: {
    updateMethod(data) {
      this.$set(this.uimodel, "data", data);
      this.$emit("update", data);
      this.$nextTick(() => this.validate());
    },

    restoreMethod() {
      const savedData = this.$cloneObject(this.uimodel.savedData);
      this.$set(this.uimodel, "data", savedData);
    },

    async validate() {
      await this.$nextTick();
      const selector = "#" + this.editorId + " form.v-form";
      const forms = document.querySelectorAll(selector);
      const isValid = [...forms].every((form) => {
        const vForm = form.__vue__;
        return this.$validateVForm(vForm);
      });
      this.$emit("update:validity", isValid);
    },
  },

  computed: {
    title() {
      return this.method.id;
    },

    editorId() {
      return "paymentMethodEditor_" + this.title.toLowerCase();
    },
  },
};
</script>

<style scoped>
</style>